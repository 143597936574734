import React from 'react'
import Transactions from './Transactions'

import '../styles/history.css' 

const History = () => {
  return (
    <>
      <div className="history offcBG beforeWas">
        <div className="histCont zl1">
          <h4>All Transactions</h4>
          <div className="hist-Cont">
            <Transactions />
          </div>
        </div>
      </div>
    </>
  )
}

export default History