import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector} from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard'
import FileBase from 'react-file-base64'
import { ColorRing } from 'react-loader-spinner'

import { deposit } from '../../database/actions/account';

import { IoIosCopy } from "react-icons/io";

import '../styles/paymentGate.css'
const PaymentGate = ({ depositGG, depositValue, setDepositValue, formData, user }) => {
  const { isLoading } = useSelector((state) => state.accounts);
  const [copied, setCopied] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDepo = async (e) => {
    e.preventDefault();
    dispatch(deposit(user.result?._id, formData, navigate, user));

  }
  return (
    <>
      <div className={"paymentGate " + (depositGG ? ' showSummaryGG' : ' hideSummaryGG')}>
        <div className="gate-container">
          <div className="gate-header"></div>

          <div className="gate-details">
            <div className="wallet-details">
              <h3>You have requested <span>{formData.amount} USD</span> , Please pay <span>{formData.payable} USD</span> for successful payment</h3>
              <h2>Please follow the instruction bellow:</h2>
              <p>Kindly make the deposit to the {depositValue?.currency} trading address below, upload payment proof and hit "PAID" when done </p>
              {copied ? <span style={{ color: 'crimson' }}> Copied </span> : null}
              <h4>{depositValue?.wallet}
                <CopyToClipboard text={depositValue?.wallet} onCopy={() => setCopied(true)}>
                  <span className='copyboard'><IoIosCopy /></span>
                </CopyToClipboard>
              </h4>
              <div className="payProof">
                <FileBase type="file" multiple={false} onDone={({ base64 }) => setDepositValue({ ...depositValue, paymentProof: base64 })} />
              </div>
              <Link onClick={handleDepo}>{isLoading ? <ColorRing  visible={true} width="25px" height="25px" ariaLabel="blocks-loading" colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']} /> : "Paid"}</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PaymentGate