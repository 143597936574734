import React from 'react'
import TradingViewWidget from './TradingViewWidget'
import Trad from './Trad'

import '../styles/tradingview.css'

const LiveTrading = () => {
  return (
    <>
      <TradingViewWidget />
      <Trad />
    </>
  )
}

export default LiveTrading