import React from 'react'
import { useInView } from 'react-intersection-observer'

import '../styles/classes.css'

const Classes = () => {
  const { ref: aRef, inView: aView} = useInView();
  const { ref: bRef, inView: bView} = useInView();
  const { ref: cRef, inView: cView} = useInView();
  const { ref: dRef, inView: dView} = useInView();
  const { ref: eRef, inView: eView} = useInView();
  const { ref: fRef, inView: fView} = useInView();
  const { ref: gRef, inView: gView} = useInView();
  const { ref: hRef, inView: hView} = useInView();
  const { ref: iRef, inView: iView} = useInView();
  const { ref: jRef, inView: jView} = useInView();
  const { ref: kRef, inView: kView} = useInView();

  return (
    <>
      <div className="classes">
        <div className="classes-cont">
          <h6 className={'anihide'  + (aView ? " anishow" : " ")} ref={aRef} style={{ "--i" : "1"}}>Risk/Trade Management</h6>
          <h2 className={'anihide'  + (bView ? " anishow" : " ")} ref={bRef} style={{ "--i" : "2"}}>4 week plan to STOP overtrading and over risking <br /> Private Forex MasterMind group</h2>
          <ul>
            <li className={'anihide left'  + (cView ? " anishow" : " ")} ref={cRef} style={{ "--i" : "1"}}>
              <p>Introduction to MetaTrader 5 and setting up software.</p>
            </li>
            <li className={'anihide right'  + (dView ? " anishow" : " ")} ref={dRef} style={{ "--i" : "2"}}>
              <p> Setting up your MetaTrader 5 in a Candle Reading format.</p>
            </li>
            <li className={'anihide left'  + (eView ? " anishow" : " ")} ref={eRef} style={{ "--i" : "3"}}>
              <p> Best Sessions to trade your choice of currency pair.</p>
            </li>
            <li className={'anihide right'  + (fView ? " anishow" : " ")} ref={fRef} style={{ "--i" : "4"}}>
              <p> Exactly what timeframe to look at in EACH session.</p>
            </li>
            <li className={'anihide left'  + (gView ? " anishow" : " ")} ref={gRef} style={{ "--i" : "5"}}>
              <p> How Timeframes correlate in volume sessions </p>
            </li>
            <li className={'anihide right'  + (hView ? " anishow" : " ")} ref={hRef} style={{ "--i" : "6"}}>
              <p> CandleSticks Basics - Currency Pairs - Zones/Ranges </p>
            </li>
            <li className={'anihide left'  + (iView ? " anishow" : " ")} ref={iRef} style={{ "--i" : "7"}}>
              <p> LIVE Q and A Session </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="commonIncen" >
        <h3 className={'anihide'  + (jView ? " anishow" : " ")} ref={jRef} style={{ "--i" : "1"}}>Our Commission Incentives is what makes TradeView Guild different</h3>
        <p className={'anihide'  + (kView ? " anishow" : " ")} ref={kRef} style={{ "--i" : "2"}}>At TradeView Guild, we are not only focused on providing safe and fair environments to trade in, but we also make sure our clients have the ability to access world class Forex price action education and Risk Management strategies and tools.There is no need for clients to pay extra to have access to these services. Our clients can use their commissions paid in PRO accounts to access services and tools that benefit their trading career.</p>
      </div>
    </>
  )
}

export default Classes