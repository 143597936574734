import React from 'react'
import { useInView } from 'react-intersection-observer'

import { FaAward, FaTools, FaUsers, FaBuilding } from 'react-icons/fa'

import '../styles/reputation.css'
const Reputation = () => {
  const { ref: aRef, inView: aView} = useInView();
  const { ref: bRef, inView: bView} = useInView();
  const { ref: cRef, inView: cView} = useInView();
  const { ref: dRef, inView: dView} = useInView();

  return (
    <>
    <div className="reputation">
      <h2> Join a club of more than 81,000 traders </h2>
      <ul>
        <li className={'anihide'  + (aView ? " anishow" : " ")} ref={aRef} style={{ "--i" : "2"}}>
          <h4><FaAward />Best reputation</h4>
          <p>Licensed and regulated across multiple jurisdictions, we serve clients in over 150 countries worldwide.</p>
        </li>
        <li className={'anihide'  + (bView ? " anishow" : " ")} ref={bRef} style={{ "--i" : "2"}}>
          <h4> <FaTools />Advanced tools</h4>
          <p>Once you have selected your package you will receive an instant invitation to join the group chat. You will see around 10 signals per day with live updates.</p>
        </li>
        <li className={'anihide'  + (cView ? " anishow" : " ")} ref={cRef} style={{ "--i" : "2"}}>
          <h4><FaUsers />1,000+ clients</h4>
          <p>The internet has taken the world by a storm in a positively unimaginable way. The business world is completely toppled by having all the resources it requires for the successful initiation of an enterprise.</p>
        </li>
        <li className={'anihide'  + (dView ? " anishow" : " ")} ref={dRef} style={{ "--i" : "2"}}>
          <h4><FaBuilding />Worldwide office</h4>
          <p>Knowing well that we are opened to investors we have added a multi currency support system for investors far and wide.</p>
        </li>
      </ul>
      <div className="img"></div>
    </div>
    </>
  )
}

export default Reputation