import React from 'react'
import { useInView } from 'react-intersection-observer'

import '../styles/offers.css';

const Offers = () => {
  const { ref: aRef, inView: aView} = useInView();
  const { ref: bRef, inView: bView} = useInView();
  const { ref: cRef, inView: cView} = useInView();
  const { ref: dRef, inView: dView} = useInView();
  const { ref: eRef, inView: eView} = useInView();
  const { ref: fRef, inView: fView} = useInView();
  const { ref: gRef, inView: gView} = useInView();

  return (
    <>
    <div className="services offers" >
      <div className="services-container">
        <div className="services-heading">
          <h2 className={ "anihide "  + (aView ? " anishow" : " ")} ref={aRef}>Why Choose <span>Us</span></h2>
          <p className={ "anihide "  + (bView ? " anishow" : " ")} ref={bRef}>The program is designed to teach you. This is perfect for intermediary to advanced traders</p>
        </div>
        <ul className="services-content" >
        <li className={ "anihide "  + (cView ? " anishow" : " ")} ref={cRef}>
            <p>Free Ultimate Trading Guide and Trade management for new traders</p>
          </li>
          <li className={ "anihide "  + (dView ? " anishow" : " ")} ref={dRef}>
            <p>Trading foundation to overcome the biggest obstacles holding you back</p>
          </li>
          <li className={ "anihide "  + (gView ? " anishow" : " ")} ref={gRef}>
            <p>Establish a streamlined risk management strategy using portfolio heat analysis</p>
          </li>
          <li className={ "anihide "  + (eView ? " anishow" : " ")} ref={eRef}>
            <p>Maintaining weekly and daily trading practices to ensure consistent performance</p>
          </li>
          <li className={ "anihide "  + (fView ? " anishow" : " ")} ref={fRef}>
            <p>Our goal is to guide you on a transformative journey and mindset needed to succeed in the markets.</p>
          </li>
        </ul>
      </div>
    </div>
    </>
  )
}

export default Offers