import bg1 from "./bg1.jpg";
import bg2 from "./bg2.png";
import bg3 from "./bg3.png";
import bg4 from "./bg4.png";
import bg5 from "./bg5.png";


const BGS = {
  bg1: bg1,
  bg2: bg2,
  bg3: bg3,
  bg4: bg4,
  bg5: bg5,
};

export default BGS;
