import React from 'react'
import { useInView } from 'react-intersection-observer'

import '../styles/difference.css'
import IMGS from '../assets/bg'

const Difference = () => {
  const { ref: aRef, inView: aView} = useInView();
  const { ref: bRef, inView: bView} = useInView();
  const { ref: cRef, inView: cView} = useInView();
  const { ref: dRef, inView: dView} = useInView();
  const { ref: eRef, inView: eView} = useInView();

  const imgBGStyles = {
    backgroundImage: `url(${IMGS.bnnr5})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  return (
    <>
      <div className="difference">
        <div className="difference-cont">
          <h4 className={'anihide' + (aView ? " anishow" : " ")} ref={aRef} style={{ "--i" : "2"}}>Why We Are Different</h4>
          <h2 className={'anihide' + (bView ? " anishow" : " ")} ref={bRef} style={{ "--i" : "3"}}>Integrated Financial Strategies providing personalised financial guidiance through asset management</h2>
          <p className={'anihide' + (cView ? " anishow" : " ")} ref={cRef} style={{ "--i" : "4"}}>Our vision is not only to provide a safe and reliable way to trade the markets, but also incentivize our clients with outstanding education and risk management techniques</p>
          <div className="vision">
            <h3 className={'anihide' + (dView ? " anishow" : " ")} ref={dRef} style={{ "--i" : "3"}}>OUR VISION </h3>
            <p className={'anihide' + (eView ? " anishow" : " ")} ref={eRef} style={{ "--i" : "2"}}>We strive to develop relationships with our clients that goes beyond financial investments. We understand that money is a vehicle used to achieve your life goals. To that end, we spend a significant amount of time trying to understand what our clients hope to accomplish in life. We then develop a prudent financial plan which allows them to obtain those goals with the least amount of risk possible.</p>
          </div>
        </div>
        <div className="difference-img" style={imgBGStyles}></div>
      </div>
    </>
  )
}

export default Difference