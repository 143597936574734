import React from 'react'
import { useInView } from 'react-intersection-observer'

import '../styles/numbers.css'

const Numbers = () => {
  const { ref: aRef, inView: aView} = useInView();
  const { ref: bRef, inView: bView} = useInView();
  const { ref: cRef, inView: cView} = useInView();
  const { ref: dRef, inView: dView} = useInView();

  return (
    <>
      <div className="numbers">
        <ul>
          <li className={'anihide' + (aView ? " anishow" : " ")} ref={aRef} style={{ "--i" : "2"}}><h2>13405<span>Happy Client</span></h2></li>
          <li className={'anihide' + (bView ? " anishow" : " ")} ref={bRef} style={{ "--i" : "2"}}><h2>12688<span>Successful Trades</span></h2></li>
          <li className={'anihide' + (cView ? " anishow" : " ")} ref={cRef} style={{ "--i" : "2"}}><h2>4.5<span>Star Rating</span></h2></li>
          <li className={'anihide' + (dView ? " anishow" : " ")} ref={dRef} style={{ "--i" : "2"}}><h2>11921<span>Certificates Given</span></h2></li>
        </ul>
      </div>  
    </>
  )
}

export default Numbers