import React, { useState } from 'react'
import { useInView } from 'react-intersection-observer'

import '../styles/questions.css'
import { BsPatchQuestion } from "react-icons/bs";

const Questions = () => {

  const [isActive, setIsActive] = useState(false);
  const [isActive1, setIsActive1] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [isActive4, setIsActive4] = useState(false);

  const { ref: aRef, inView: aView} = useInView();
  const { ref: bRef, inView: bView} = useInView();
  const { ref: cRef, inView: cView} = useInView();
  const { ref: dRef, inView: dView} = useInView();
  const { ref: eRef, inView: eView} = useInView();
  const { ref: fRef, inView: fView} = useInView();
  const { ref: gRef, inView: gView} = useInView();


  return (
    <>
    <div className="questions">
      <div className="questions-container">
        <div className="questions-heading">
          <h2 className={"anihide"  + (aView ? " anishow" : " ")} ref={aRef}  style={{ "--i" : "1"}}> Frequently Asked <span>Questions</span></h2>
          <p className={"anihide"  + (bView ? " anishow" : " ")} ref={bRef} style={{ "--i" : "1"}}>We answer some of your Frequently Asked Questions regarding our platform. If you have a query that is not answered here, Please contact us.</p>
        </div>
        <ul className="questionsAsked">
          <li className={"anihide left"  + (cView ? " anishow" : " ")} ref={cRef} style={{ "--i" : "1"}}>
            <h3 onClick={(e) => setIsActive(!isActive)} className={" " + (isActive ? ' active' : ' ')}> <BsPatchQuestion /> When can I deposit/withdraw from my Investment account?</h3>
            <div className={"qanswered" + (isActive ? ' active' : ' inactive')}>
            <p>
              Deposit and withdrawal are available for at any time. Be sure, that your funds are not used in any ongoing trade before the withdrawal. The available amount is shown in your dashboard on the main page of Investing platform.
            </p>
            </div>
          </li>
          <li className={"anihide right" + (dView ? " anishow" : " ")} ref={dRef}  style={{ "--i" : "1"}}>
            <h3 onClick={(e) => setIsActive1(!isActive1)} className={" " + (isActive1 ? ' active' : ' ')}> <BsPatchQuestion /> How do I check my account balance?</h3>
            <div className={"qanswered" + (isActive1 ? ' active' : ' inactive')}>
            <p>You can see this anytime on your accounts dashboard.</p>
            </div>
          </li>
          <li className={"anihide left"  + (eView ? " anishow" : " ")} ref={eRef} style={{ "--i" : "1"}}>
            <h3 onClick={(e) => setIsActive2(!isActive2)} className={" " + (isActive2 ? ' active' : ' ')}> <BsPatchQuestion /> I forgot my password, what should I do?</h3>
            <div className={"qanswered" + (isActive2 ? ' active' : ' inactive')}>
            <p>Visit the password reset page, type in your email address and click the `Reset` button.</p>
            </div>
          </li>
          <li className={"anihide right" + (fView ? " anishow" : " ")} ref={fRef}  style={{ "--i" : "1"}}>
            <h3 onClick={(e) => setIsActive3(!isActive3)} className={" " + (isActive3 ? ' active' : ' ')}> <BsPatchQuestion /> How will I know that the withdrawal has been successful?</h3>
            <div className={"qanswered" + (isActive3 ? ' active' : ' inactive')}>
            <p>You will get an automatic notification once we send the funds and you can always check your transactions or account balance. Your chosen payment system dictates how long it will take for the funds to reach you.</p>
            </div>
          </li>
          <li className={"anihide left"  + (gView ? " anishow" : " ")} ref={gRef} style={{ "--i" : "1"}}>
            <h3 onClick={(e) => setIsActive4(!isActive4)} className={" " + (isActive4 ? ' active' : ' ')}> <BsPatchQuestion /> How much can I withdraw?</h3>
            <div className={"qanswered" + (isActive4 ? ' active' : ' inactive')}>
            <p>You can withdraw the full amount of your account balance minus the funds that are used currently for supporting opened positions.</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    </>
  )
}

export default Questions