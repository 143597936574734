import React from 'react'

import '../styles/BG.css'

const BG = () => {

  return (
    <>
      <div className="overallbg" ></div>
    </>
  )
}

export default BG