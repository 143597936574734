import React from 'react'
import { useInView } from 'react-intersection-observer'

import '../styles/payment.css';
import Payments from '../assets/payment'

const Payment = () => {
  const { ref: aRef, inView: aView} = useInView();
  const { ref: bRef, inView: bView} = useInView();
  const { ref: cRef, inView: cView} = useInView();

  const blockchain = {
    backgroundImage: `url(${Payments.blockchain})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  const coinbase = {
    backgroundImage: `url(${Payments.coinbase})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  const coingate = {
    backgroundImage: `url(${Payments.coingate})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  const coinomi = {
    backgroundImage: `url(${Payments.coinomi})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  const mollie = {
    backgroundImage: `url(${Payments.mollie})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  const paypal = {
    backgroundImage: `url(${Payments.paypal})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  const pm = {
    backgroundImage: `url(${Payments.pm})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  const stripe = {
    backgroundImage: `url(${Payments.stripe})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  return (
    
    <>
    <div className="payment">
      <div className="payment-container">
        <div className="payment-header">
          <h2 className={'anihide'  + (aView ? " anishow" : " ")} ref={aRef} style={{ "--i" : "1"}}>Payment We Accept</h2>
          <p className={'anihide'  + (bView ? " anishow" : " ")} ref={bRef} style={{ "--i" : "2"}}>We accept all major cryptocurrencies and fiat payment methods to make your investment process easier with our platform.</p>
        </div>
        <div className="payment-slider">
          <ul className={'anihide'  + (cView ? " anishow" : " ")} ref={cRef} style={{ "--i" : "2"}}>
            <li> <div className="stripe" style={stripe}></div></li>
            <li> <div className="paypal" style={paypal}></div></li>
            <li> <div className="blockchain" style={blockchain}></div></li>
            <li> <div className="coinbase" style={coinbase}></div></li>
            <li> <div className="coingate" style={coingate}></div></li>
            <li> <div className="mollie" style={mollie}></div></li>
            <li> <div className="perfectMoney" style={pm}></div></li>
            <li> <div className="coinomi" style={coinomi}></div></li>
          </ul>
        </div>
      </div>
    </div>
    </>
  )
}

export default Payment