import React from 'react'

import BG from './BG'
import Banner from './Banner'
import Services from './Services'
import Difference from './Difference'
import Classes from './Classes'
import Questions from './Questions'
import Comments from './Comments'
import Bootcamp from './Bootcamp'
import Numbers from './Numbers'
import GetStated from './GetStated'
import Payment from './Payment'
import NewsLetter from './NewsLetter'
import Analysis from './Analysis'
import Offers from './Offers'
import Reputation from './Reputation'
import Trusted from './Trusted'
import Partner from './Partner'
import Steps from './Steps'
import Certificate from './Certificate'
import Register from './Register'

const Index = () => {
  return (
    <>
      <BG />
      <Banner />
      <Services />
      <Offers />
      <Difference />
      <Classes />
      <Analysis />
      <Questions />
      <Reputation />
      <Comments />
      <Trusted />
      {/* <TeamWork /> */}
      <Partner />
      <Bootcamp />
      <Numbers />
      <GetStated />
      <Register />
      <Steps />
      <Payment />
      <NewsLetter />
      <Certificate />
    </>
  )
}

export default Index