import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import '../styles/profsettings.css'
 
import { updateProfile, getAccount } from '../../database/actions/account';
const ProfSettings = () => {
  
  const {account} = useSelector((state) => state.accounts);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { id } = useParams();

  // useEffect(() => {
  //   dispatch(getAccount(id));
  // }, [])

  const initialState = {
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    number: '',
    country: '',
    region: '',
    city: '',
    address: '',
    zip: '',
    password: '',
    confirmPassword: '',
    text: ''
  }
  const [formData, setFormData] = useState(initialState);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(formData.password !== formData.confirmPassword){
      setFormData({...formData, text: "Password don't match"})
    } else{
      setFormData({...formData})
      dispatch(updateProfile(id, formData, navigate));
    }
  }
  return (
    <>
      <div className="psetting sttnBG beforeWas">
        <div className="set-heading zl1"> <h2>Settings {formData.text} </h2></div>
        <div className="set-cont zl1">
          <form onSubmit={handleSubmit}>
            <div className="ppix">
              <div className="pix"><p>{user.result?.firstName.charAt(0)}{user.result?.lastName.charAt(0)}</p></div>
            </div>
            <ul className="names">
              <li>
                <input type="text" placeholder={user.result?.firstName} defaultValue={user.result?.firstName} name='firstName' id='firstName' required disabled />
                <div className="underline"></div>
                <label htmlFor="firstName">First Name</label>
              </li>
              <li>
                <input type="text" placeholder={user.result?.lastName} defaultValue={user.result?.lastName} name='lastName' id='lastName' required disabled />
                <div className="underline"></div>
                <label htmlFor="lastName">Last Name</label>
              </li>
              <li>
                <input type="text" placeholder={user.result?.userName} defaultValue={user.result?.userName} name='userName' id='userName' disabled />
                <div className="underline"></div>
                <label htmlFor="userName">Userame</label>
              </li>
              <li>
                <input type="email" placeholder={user.result?.email} defaultValue={user.result?.email} name='email' id='email' disabled />
                <div className="underline"></div>
                <label htmlFor="email">Email</label>
              </li>
            </ul>
            <ul className="address">
              <li>
                <input type="number" placeholder={user.result?.number} name='number' id='number'  onChange={handleChange} required />
                <div className="underline"></div>
                <label htmlFor="number">Mobile</label>
              </li>
              <li>
                <input type="text" placeholder={user.result?.country} name='country' id='country' onChange={handleChange} required />
                <div className="underline"></div>
                <label htmlFor="country">Country</label>
              </li>
              
              <li>
                <input type="text" placeholder={user.result?.region} name='region' id='region' onChange={handleChange} />
                <div className="underline"></div>
                <label htmlFor="state">Region</label>
              </li>
              <li>
                <input type="text" placeholder={user.result?.city} name='city' id='city' onChange={handleChange} required/>
                <div className="underline"></div>
                <label htmlFor="city">City</label>
              </li>
              <li>
                <input type="text" placeholder={user.result?.address} name='address' id='address' onChange={handleChange} required/>
                <div className="underline"></div>
                <label htmlFor="address">Address</label>
              </li>
              <li>
                <input type="number" placeholder={user.result?.zip} name='zip' id='zip' onChange={handleChange} required/>
                <div className="underline"></div>
                <label htmlFor="zip">zip</label>
              </li>
            </ul>
            <ul className="password">
              <li>
                <input type="password"  name='password' id='password' onChange={handleChange} required/>
                <div className="underline"></div>
                <label htmlFor="password">Password</label>
              </li>
              <li>
                <input type="password"  name='confirmPassword' id='confirmPassword' onChange={handleChange} required/>
                <div className="underline"></div>
                <label htmlFor="confirmPassword">Confirm Password</label>
              </li>
            </ul>
            <button type='submit' className='btn-hover'>Update</button>
          </form>
        </div>
      </div>
    </>
  )
}

export default ProfSettings