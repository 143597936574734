import React from 'react'
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer'

import '../styles/banner.css'

const Banner = () => {
  const { ref: aRef, inView: aView} = useInView();
  const { ref: bRef, inView: bView} = useInView();
  const { ref: cRef, inView: cView} = useInView();
  const { ref: dRef, inView: dView} = useInView();

  return (
    <>
      <div className="banner" >
        <div className="banner-cont">
          <h1 ><span>UNLIMITED</span> possibilities with TECHNOLOGY <span>that EARNS more PROFIT</span></h1>
          <h3 className={'anihide left' + (aView ? " anishow" : " ")} ref={aRef} style={{ "--i" : "5"}}>Adding analysis tool to benefit your trading success</h3>
          <div className="butts">
          <Link to='/auth' className={"btn-hover anihide left"  + (bView ? " anishow" : " ")} ref={bRef} style={{ "--i" : "5"}}>Sign In</Link>
          <Link to='/signup' className={"btn-hover anihide right" + (cView ? " anishow" : " ")} ref={cRef} style={{ "--i" : "5"}}>Sign Up</Link>
          </div>
          <h4 className={'anihide right' + (dView ? " anishow" : " ")} ref={dRef} style={{ "--i" : "6"}}>Contact Us: <a href="tel:+447760617781">+447760617781</a></h4>
        </div>
      </div>
    </>
  )
}

export default Banner