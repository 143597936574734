import React from 'react'
import { useInView } from 'react-intersection-observer'

import { FaEdit, FaMoneyBillWave, FaChartLine } from 'react-icons/fa'

import '../styles/steps.css'

const Steps = () => {
  const { ref: aRef, inView: aView} = useInView();
  const { ref: bRef, inView: bView} = useInView();
  const { ref: cRef, inView: cView} = useInView();

  return (
    <>
      <div className="steps">
        <div className="steps-cont">
          <ul>
            <li className={'anihide' + (aView ? " anishow" : " ")} ref={aRef} style={{ "--i" : "1"}}>
              <h3><FaEdit />Register</h3>
              <p>Fill in your personal details in our secure online application.</p>
            </li>
            <span></span>
            <li className={'anihide' + (bView ? " anishow" : " ")} ref={bRef} style={{ "--i" : "2"}}>
              <h3><FaMoneyBillWave />Deposit</h3>
              <p>Make a deposit via debit card, wire transfer, eCheck or check.</p>
            </li>
            <span></span>
            <li className={'anihide' + (cView ? " anishow" : " ")} ref={cRef} style={{ "--i" : "3"}}>
              <h3><FaChartLine />Trading</h3>
              <p>Once your approved, you can trade on desktop and mobile.</p>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Steps