import React from 'react'
import { useInView } from 'react-intersection-observer'

import '../styles/trusted.css'

const Trusted = () => {
  const { ref: aRef, inView: aView} = useInView();
  const { ref: bRef, inView: bView} = useInView();
  const { ref: cRef, inView: cView} = useInView();

  return (
    <>
      <div className="trusted">
        <div className="trusted-cont ">
          <section>
            <h2>Trusted for more than 7 years</h2>
            <p>TradeView Guild is an online Forex and cryptocurrency STP broker providing CFD trading on hundreds of assets and optimal trading conditions within the award-winning MT4 platform. The TradeView Guild offers deep liquidity, generous leverage up to 1:500, and some of the best spreads in the industry. As part of our commitment to our client’s satisfaction, we offer 24/7 live customer service, charge no deposit or withdrawal fees, and process withdrawals within 30-minutes or less. We feel that these, along with many other advantages, help to set us apart from the rest.</p>
            <h5>Multi-award winner</h5>
          </section>
          <ul>
            <li className={"anihide " + (aView ? " anishow" : " ")} ref={aRef} style={{ "--i" : "2"}}>
              <div className="svg"></div>
              <h4>Best CFD Broker</h4>
              <p>TradeON Summit 2020</p>
            </li>
            <li className={"anihide " + (bView ? " anishow" : " ")} ref={bRef} style={{ "--i" : "4"}}>
              <div className="svg"></div>
              <h4>Best Trading Experience</h4>
              <p>Jordan Forex EXPO 2015</p>
            </li>
            <li className={"anihide " + (cView ? " anishow" : " ")} ref={cRef} style={{ "--i" : "6"}}>
              <div className="svg"></div>
              <h4>Best Execution Broker</h4>
              <p>TradeON Summit 2020</p>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Trusted