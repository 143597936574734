import React from 'react'
import { Link } from 'react-router-dom';

import { FcCurrencyExchange } from "react-icons/fc"

const Logo = () => {
  return (
    <>
      <div className="logo">
        <div className="inner">
          <Link to='/'>
            <FcCurrencyExchange />
            <h2>TradeView <span>Guild</span></h2>
          </Link>
        </div>
      </div>
    </>
  )
}

export default Logo