import React from "react";
import { useInView } from 'react-intersection-observer'

import '../styles/comments.css';
import IMGS from '../../assets/bg'

const Comments = () => {
  const { ref: aRef, inView: aView} = useInView();
  const { ref: bRef, inView: bView} = useInView();
  const { ref: cRef, inView: cView} = useInView();
  const { ref: dRef, inView: dView} = useInView();
  const { ref: eRef, inView: eView} = useInView();

  const imgBGStyles = {
    backgroundImage: `url(${IMGS.cmmts2})`,
    backgroundSize: "cover",
    backgroundPosition: "top",
  };

  return (
    <>
      <div className="comments" >
        <div className="comments-container">
          <div className="othersComments" style={imgBGStyles}>
            <h2 className={"anihide"  + (aView ? " anishow" : " ")} ref={aRef}  style={{ "--i" : "1"}}>What Users Say <span>About Us</span></h2>
            <p className={"anihide"  + (bView ? " anishow" : " ")} ref={bRef}  style={{ "--i" : "2"}}>We are doing really good at this market and our users have great feedbacks.</p>
          </div>
        </div>
        <ul>
          <li className={"anihide"  + (cView ? " anishow" : " ")} ref={cRef}  style={{ "--i" : "1"}}>
            <div className="img"></div>
            <p >I had never operated with any broker, I am paying to learn and with my losses I am developing a different way of working on the platform, I do not blame them for my losses because I lack the knowledge, planning, strategy and discipline that I am gradually getting, I fully trust in broker, I am a customer account STAYFOCUSKIM and I believe that I will continue to operate and reduce my loss over time, it may take months or a year but I will not give up, the platform is not complicated but it needs</p>
            <p className="client">Kimberly Adams <span>UNITED STATES</span></p>

          </li>
          <li className={"anihide"  + (dView ? " anishow" : " ")} ref={dRef} style={{ "--i" : "1"}}>
            <div className="img"></div>
            <p >I've just got started with forex and The Alert Nation have helped me so much. They gave me a free course and Jamie and Luke gave me their direct contact number so they could answer all my questions and guide me through everything. I've been placing my own trades from my own analysis which i've been taught from them how to do, and am making good money using low lot sizes. I've learned so much and am so grateful my friend recommended you guys.</p>
            <p className="client">MANDEEP SINGH <span>INDIAN</span></p>

          </li>
          <li className={"anihide"  + (eView ? " anishow" : " ")} ref={eRef} style={{ "--i" : "1"}}>
            <div className="img"></div>
            <p >Still getting to grips with Forex and applying that to the service (but thatâ€™s my issue not down to the level of service received) The course is put together in a slightly crude manor but we pay for quality content not for fancy camera work so Iâ€™m very happy with what I have been sent so far. The price of the service doesnâ€™t break the bank either so it allows new people like myself who have been sat on the fence to get involved with no pressure. Would recommend to anyone</p>
            <p className="client">Lauren Nicholson <span>ENGLAND</span></p>

          </li>
        </ul>
      </div>
    </>
  );
};

export default Comments;
