import bnnr1 from "./bnnr1.jpg";
import bnnr3 from "./bnnr3.jpg";
import bnnr4 from "./bnnr4.jpg";
import bnnr5 from "./bnnr5.jpg";
import appbg5 from "./appbg5.jpg";
import bg1 from "./bg1.jpg";
import bg2 from "./bg2.jpg";
import offc5 from "./offc5.jpg";

const IMGS = {
  bnnr1: bnnr1,
  bnnr3: bnnr3,
  bnnr4: bnnr4,
  bnnr5: bnnr5,
  appbg5: appbg5,
  bg1: bg1,
  bg2: bg2,
  offc5: offc5,

};

export default IMGS;
