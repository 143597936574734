import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer'
import { useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom";

import { signup } from '../../database/actions/auth';

import '../styles/register.css'

const Register = () => {
  const { ref: aRef, inView: aView} = useInView();
  const { ref: bRef, inView: bView} = useInView();
  const { ref: cRef, inView: cView} = useInView();
  const { ref: dRef, inView: dView} = useInView();
  const { ref: eRef, inView: eView} = useInView();
  const { ref: fRef, inView: fView} = useInView();
  const { ref: gRef, inView: gView} = useInView();

  const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    userName: '',
    password: '',
    confirmPassword: ''
  }
  const [formData, setFormData] = useState(initialState);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(signup(formData, navigate));

  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }
  return (
    <>
      <div className="register">
        <div className="reg-cont">
          <form onSubmit={handleSubmit}>
            <div className={"form-cont anihide" + (aView ? " anishow" : " ")} ref={aRef} style={{ "--i" : "1"}}>
              <input type="text" name="firstName" id="firstName" onChange={handleChange} required />
              <div className="underline"></div>
              <label htmlFor="firstName">First Name</label>
            </div>
            <div className={"form-cont anihide" + (bView ? " anishow" : " ")} ref={bRef} style={{ "--i" : "2"}}>
              <input type="text" name="lastName" id="lastName" onChange={handleChange} required />
              <div className="underline"></div>
              <label htmlFor="lastName">Last Name</label>
            </div>
            <div className={"form-cont anihide" + (cView ? " anishow" : " ")} ref={cRef} style={{ "--i" : "3"}}>
              <input type="text" name='userName' id='userName' onChange={handleChange} required />
              <div className="underline"></div>
              <label htmlFor="userName">Username</label>
            </div>
            <div className={"form-cont anihide" + (dView ? " anishow" : " ")} ref={dRef} style={{ "--i" : "4"}}>
              <input type="email" name="email" id="email" onChange={handleChange} required />
              <div className="underline"></div>
              <label htmlFor="email">Email</label>
            </div>

            <div className={"form-cont anihide" + (eView ? " anishow" : " ")} ref={eRef} style={{ "--i" : "5"}}>
              <input type="password" name="password" id="password" onChange={handleChange} required />
              <div className="underline"></div>
              <label htmlFor="password">Password</label>
            </div>
            <div className={"form-cont anihide" + (fView ? " anishow" : " ")} ref={fRef} style={{ "--i" : "6"}}>
              <input type="password" name="confirmPassword" id="confirmPassword" onChange={handleChange} required />
              <div className="underline"></div>
              <label htmlFor="confirmPassword">Confirm Password</label>
            </div>
            <button className={'btn-hover anihide' + (gView ? " anishow" : " ")} ref={gRef} style={{ "--i" : "7"}} type="submit">Sign Up</button>
          </form>
        </div>
      </div>
    </>
  )
}

export default Register