import React from "react";
import { useNavigate } from "react-router-dom";
import { useInView } from 'react-intersection-observer'

import '../styles/news.css'
import { BiEnvelope } from "react-icons/bi";

const NewsLetter = () => {
  const { ref: aRef, inView: aView} = useInView();
  const { ref: bRef, inView: bView} = useInView();
  const { ref: cRef, inView: cView} = useInView();

  const navigate = useNavigate();

  const onSubmit = () => {
    navigate('mailto:triaengles@gmail.com')
  }
  return (
    <>
      <div className="news">
        <div className="news-header">
          <h2 className={'anihide' + (aView ? " anishow" : " ")} ref={aRef} style={{ "--i" : "1"}}>Get Updates</h2>
          <p className={'anihide' + (bView ? " anishow" : " ")} ref={bRef} style={{ "--i" : "2"}}>By subscribing to our mailing list you will always be update with the latest news from us.</p>
        </div>
        <div className={"newsletter anihide" + (cView ? " anishow" : " ")} ref={cRef} style={{ "--i" : "3"}}>
          <h2>Subscribe to Our Newsletter</h2>
          <form onSubmit={onSubmit}>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Email Address"
            />
            <button type="submit">
              <BiEnvelope />
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default NewsLetter;
