import React from 'react'

import '../styles/commission.css';

const Commissions = () => {
  return (
    <>
      <div className="commission refBG beforeWas">
        <div className="comm-header zl1">
          <h2>Referral Commission</h2>
        </div>
        <div className="comm-cont zl1">
          <div className="transactions">
            <table className="transact">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>From</th>
                  <th>Level</th>
                  <th>Percent</th>
                  <th>Amount</th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='amount' data-label="Date">...</td>
                  <td data-label="From">...</td>
                  <td data-label="Level">...</td>
                  <td className='idid' data-label="Percent">...</td>
                  <td data-label="Amount">...</td>
                  <td className='wallet' data-label="Type">...</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default Commissions