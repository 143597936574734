import blockchain from "./blockchain.jpg";
import coinbase from "./coinbase.png";
import coingate from "./coingate.png";
import coinomi from "./coinomi.png";
import mollie from "./mollie.jpg";
import paypal from "./paypal.png";
import pm from "./pm.png";
import stripe from "./stripe.jpg";


const Payments = {
    blockchain: blockchain,
    coinbase: coinbase,
    coingate: coingate,
    coinomi: coinomi,
    mollie: mollie,
    paypal: paypal,
    pm: pm,
    stripe: stripe,
  };
  
  export default Payments;
  