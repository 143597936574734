import React from 'react';
import { Link } from 'react-router-dom';

import '../styles/depositPP.css'

const DepositPP = ({depositPP, setDepositPP, depositValue, setDepositGG}) => {
  const handleSub = (e) => {
    e.preventDefault();
    setDepositPP(false)
    setDepositGG(true)
  }
  return (
    <>
      <div className={"depositPP " + (depositPP ? ' showSummary' : ' hideSummary')}>
        <div className="container">
          <ul>
            <li><h3>Currency: </h3><p>{depositValue.currency}</p></li>
            <li><h3>Amount: </h3><p>${depositValue.amount}</p></li>
            <li><h3>Charges: </h3><p>+${depositValue.charges}</p></li>
            <li><h3>Payable: </h3><p>+${depositValue.payable}</p></li>
            <p><Link onClick={handleSub} className='btn-hover'>Confirm</Link></p>
            <h4 onClick={(e) => setDepositPP(false)} className='btn-hover'>Cancel</h4>
          </ul>
        </div>
      </div>
    </>
  )
}

export default DepositPP