import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from "react-router-dom";
import moment from 'moment';

import '../styles/withdrawal.css'
import IMGS from '../../assets/bg'

import { withdraw, getAccount } from '../../database/actions/account';

const Withdrawal = () => {
  const { account } = useSelector((state) => state.accounts);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const [withdrawalValue, setwithdrawalValue] = useState({ wallet: '', username: '', amount: '', charge: '', payable: '', total: '', text: '' });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  // useEffect(() => {
  //   dispatch(getAccount(id));
  // }, [])

  const imgBGStyles = {
    backgroundImage: `url(${IMGS.offc})`,
    backgroundSize: "cover",
    backgroundPosition: "top",
  };

  useEffect(() => {
    let percent = 0;
    let payable = 0;
    if (withdrawalValue.amount < 5) {
      setwithdrawalValue({ ...withdrawalValue, text: 'Minimum Withdrawal: $5' });
    }
    else if (withdrawalValue.amount > 5) {
      percent = (withdrawalValue.amount * 0.0125).toFixed(3);
      payable = Number(withdrawalValue.amount) - Number(percent);
      setwithdrawalValue({ ...withdrawalValue, total: payable, charge: percent, payable: payable });
    }
  }, [withdrawalValue.amount]);

  const handleWithdrawal = async (e) => {
    e.preventDefault();

    let balance = Number(user.result?.transaction?.balance?.mainAccount)
    balance = Number(user.result?.transaction?.balance?.mainAccount) - Number(withdrawalValue.payable)

    const formData = {
      "username": `${withdrawalValue.username}`, "charge": `+ ${withdrawalValue.charge}`,
      "payable": withdrawalValue.payable, "amount": Number(withdrawalValue.amount),
      "details": `Withdrawal via ${withdrawalValue.wallet}`, "status": `Pending`,
      "balance": balance
    }

    if (user.result?.transaction?.balance?.mainAccount < withdrawalValue.payable) {
      setwithdrawalValue({ ...withdrawalValue, text: "Not Sufficient Balance" });
    }
    else if (user.result?.transaction?.balance?.mainAccount > withdrawalValue.payable) {
      setwithdrawalValue({ ...withdrawalValue, text: " " });
      dispatch(withdraw(id, formData, navigate, user));
    }
  }

  return (
    <>
      <div className="withdraw beforeWas" style={imgBGStyles}>
        <div className="withdraw-header zl1">
          <h2>Withdrawal</h2>
        </div>
        <div className="withdraw-container zl1">
          <div className="withdraw-form ">
            <form onSubmit={handleWithdrawal}>
              <div className="form-cont transfer-input">
                <input type="number" name="amount" id="amount" value={withdrawalValue.amount} onChange={(e) => setwithdrawalValue({ ...withdrawalValue, amount: e.target.value })} required />
                <div className="underline"></div>
                <label htmlFor="amount">Amount</label>
              </div>
              <div className="total">
                <h3>${withdrawalValue.total ? withdrawalValue.total : '0'} <span> Total: -1.25% (gas fee) </span> </h3>

              </div>
              <select name="wallet" id="wallet" value={withdrawalValue.wallet} onChange={(e) => setwithdrawalValue({ ...withdrawalValue, wallet: e.target.value })} required>
                <option value="Bitcoin">Bitcoin</option>
                <option value="Ethereum">Ethereum</option>
                <option value="Litecoin">Litecoin</option>
                <option value="USDT">USDT</option>
              </select>
              <div className="form-cont transfer-input">
                <input type="text" name="username" id="username" value={withdrawalValue.username} onChange={(e) => setwithdrawalValue({ ...withdrawalValue, username: e.target.value })} required />
                <div className="underline"></div>
                <label htmlFor="username">Reciever's Address</label>
              </div>
              <button type="submit" className='btn-hover'>Request</button>
            </form>
          </div>
          <div className="transactions lgwd8">
            <table className="transact">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Transaction ID</th>
                  <th>Name</th>
                  <th>Amount</th>
                  <th>Gas Fee</th>
                  <th>Total</th>
                  <th>Details</th>
                  <th>Status</th>
                  <th>Balance</th>
                </tr>
              </thead>
              <tbody>
                {account?.transaction?.withdraw && account.transaction.withdraw.map((option, index) => (
                  <tr key={index}>
                    <td data-label="Date">{moment(option?.date).fromNow()}</td>
                    <td className='idid' data-label="Transaction ID">{option?._id}</td>
                    <td data-label="Name">{option?.username}</td>
                    <td className='amount' data-label="Amount">${option?.amount}</td>
                    <td data-label="Gas fee">{option?.charge}</td>
                    <td data-label="Total">${option?.payable}</td>
                    <td data-label="Details">{option?.details}</td>
                    <td data-label="Status">{option?.status}</td>
                    <td data-label="Post Balance">${option?.balance}</td>
                  </tr>
                ))
                }

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default Withdrawal