import React from 'react'
import { useInView } from 'react-intersection-observer'

import { Link } from "react-router-dom";
import '../styles/gStarted.css';

const GetStated = () => {
  const { ref: aRef, inView: aView} = useInView();

  return (
    <>
      <div className="gStarted" >
        <div className={"gStarted-container anihide" + (aView ? " anishow" : " ")} ref={aRef} style={{ "--i" : "2"}}>
          <h2>Get Started Today With Us</h2>
          <p>This is a Revolutionary Money Making Platform! Invest for Future in Stable Platform and Make Fast Money. Not only we guarantee the fastest and the most exciting returns on your investments, but we also guarantee the security of your investment.</p>
          <Link to='/signup'>Join Us</Link>
        </div>
      </div>
    </>
  )
}

export default GetStated