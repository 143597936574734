import React from 'react'
import { useInView } from 'react-intersection-observer'

import '../styles/bootcamp.css'

const Bootcamp = () => {
  const { ref: aRef, inView: aView} = useInView();
  const { ref: bRef, inView: bView} = useInView();
  const { ref: cRef, inView: cView} = useInView();

  return (
    <>
      <div className="bootcamp">
        <div className="bootcamp-cont">
          <h6 className={'dashed-txt anihide' + (aView ? " anishow" : " ")} ref={aRef} style={{ "--i" : "1"}}>Bootcamp/Seminars</h6>
          <h2 className={'boldhead anihide' + (bView ? " anishow" : " ")} ref={bRef} style={{ "--i" : "1"}}>Please note our classes will be scheduled as follows:</h2>
          <ul className={'anihide' + (cView ? " anishow" : " ")} ref={cRef} style={{ "--i" : "1"}}>
            <li >
              <h4>Class 1</h4>
              <p> Friday 4th August : <span>2pm - 6pm New York time</span></p>
            </li>
            <li >
              <h4>Class 2</h4>
              <p>Saturday 5th August : <span>2pm - 6pm New York time</span></p>
            </li>
            <li >
              <h4>Class 3</h4>
              <p>Friday 11th August : <span>2pm - 6pm New York time</span></p>
            </li>
            <li >
              <h4>Class 4</h4>
              <p>Saturday 12th August : <span>2pm - 6pm New York time</span></p>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Bootcamp