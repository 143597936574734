import React from 'react'
import { useInView } from 'react-intersection-observer'

import '../styles/analysis.css'

const Analysis = () => {
  const { ref: aRef, inView: aView} = useInView();
  const { ref: bRef, inView: bView} = useInView();
  const { ref: cRef, inView: cView} = useInView();
  const { ref: dRef, inView: dView} = useInView();
  const { ref: eRef, inView: eView} = useInView();
  const { ref: fRef, inView: fView} = useInView();
  const { ref: gRef, inView: gView} = useInView();
  return (
    <>
    <div className="analysis">
        <div className="analysis-left">
          <div className={"img anihide" + (aView ? " anishow" : " ")} style={{ "--i" : "1"}} ref={aRef}></div>
          <h2 className={'anihide'  + (bView ? " anishow" : " ")} style={{ "--i" : "2"}} ref={bRef}> Market analysis and trade inspiration</h2>
          <h4 className={'anihide'  + (cView ? " anishow" : " ")} style={{ "--i" : "3"}} ref={cRef}>The AKTM brand has become a global leader in online trading, specializing in forex, derivatives on US and UK stocks, commodities, spot metals, and indices.</h4>
          <ul>
            <li className={'anihide'  + (dView ? " anishow" : " ")} style={{ "--i" : "2"}} ref={dRef}>
              <div className="img" ></div>
              <h4>Strategies & Discussions</h4>
            </li>
            <li className={'anihide'  + (eView ? " anishow" : " ")} style={{ "--i" : "4"}} ref={eRef}>
              <div className="img"></div>
              <h4>Forecasts & Educations</h4>
            </li>
          </ul>
        </div>
        <div className="analysis-right">
        <iframe className={'anihide'  + (fView ? " anishow" : " ")} ref={fRef} style={{ "--i" : "2"}} src="https://www.youtube.com/embed/nY234RoQeHw" title="YouTube video player" allowFullScreen></iframe>
          <p className={'anihide'  + (gView ? " anishow" : " ")} ref={gRef} style={{ "--i" : "3"}}>Explore the markets at your own pace with short online courses. Sign up</p>
        </div>
    </div>
    </>
  )
}

export default Analysis