import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from "react-router-dom";
import { ColorRing } from 'react-loader-spinner'

const Forml = ({ signin, signup, isSignUp, setIsSignUp }) => {
  const errState = {
    mail: '', pw: ' ' , ax: '', err: ''
  }
  
  const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    userName: '',
    password: '',
    confirmPassword: ''
  }
  
  const { isLoading } = useSelector((state) => state.auth);
  const [formData, setFormData] = useState(initialState);
  const [errMSG, setErrMSG] = useState(errState);
  const [siren, setSiren] = useState(false)
  const [sirenP, setSirenP] = useState(false)
	const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (errMSG.err === `401`){
      setErrMSG({ ...errMSG, mail: `User don't exist`})
      setSiren(true)
      setSirenP(false)
    } else if (errMSG.err === `402`){
      setErrMSG({...errMSG, pw: `incorect password`})
      setSiren(false)
      setSirenP(true)
    }else if (errMSG.err === `500`){
      setErrMSG({...errMSG, ax: `poor connection!!!`})
      setSiren(false)
      setSirenP(false)
    } else {
      setErrMSG({mail: ``, pw: ``, ax: ``})
      setSiren(false)
      setSirenP(false)
    }
  }, [errMSG.err])

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSignUp) {
      dispatch(signup(formData, navigate));
    }
    else if ((!isSignUp)) {
      dispatch(signin(formData, navigate, setErrMSG));
    }
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const switchMode = () => {
    setIsSignUp((prevIsSignUp) => !prevIsSignUp);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
      <h6>{errMSG.ax}</h6>
        {
          isSignUp && (
            <>
              <div className="form-cont">
                <input type="text" name="firstName" id="firstName" onChange={handleChange} required />
                <div className="underline"></div>
                <label htmlFor="firstName">First Name</label>
              </div>
              <div className="form-cont">
                <input type="text" name="lastName" id="lastName" onChange={handleChange} required />
                <div className="underline"></div>
                <label htmlFor="lastName">Last Name</label>
              </div>
              <div className="form-cont">
                <input type="text" name='userName' id='userName' onChange={handleChange} required />
                <div className="underline"></div>
                <label htmlFor="userName">Username</label>
              </div>
            </>
          )
        }
        <div className={"form-cont" + ( siren ? " invalid" : " ")}>
          <input type="email" name="email" id="email" onChange={handleChange} required />
          <div className="underline"></div>
          <label htmlFor="email">Email</label>
          <h6>{errMSG.mail}</h6>
        </div> 

        <div className={"form-cont" + ( sirenP ? " invalid" : " ")}>
          <input type="password" name="password" id="password" onChange={handleChange} required />
          <div className="underline"></div>
          <label htmlFor="password">Password</label>
          <h6>{errMSG.pw}</h6>
        </div>
        {
          isSignUp &&
          <div className="form-cont">
            <input type="password" name="confirmPassword" id="confirmPassword" onChange={handleChange} required />
            <div className="underline"></div>
            <label htmlFor="confirmPassword">Confirm Password</label>
          </div>
        }
        {
          isSignUp && (
            <div className='tnc'>
              <input type="checkbox" name="tnc" id="tnc" required />
              <label htmlFor="tnc" className='tnc'> I agree with <Link to='/auth'> Privacy & Policy</Link>,<Link to='/auth'>Terms & Condition</Link></label>
            </div>
          )
        }
        <button className='btn-hover' type="submit">{isLoading ? <ColorRing visible={true} width="25px" height="25px" ariaLabel="blocks-loading" colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']} /> : (isSignUp ? 'Sign Up' : 'Sign In')}  </button>
        <p onClick={switchMode}> {
          isSignUp ? 'Have an account? Sign In' : "Don't Have an account? Sign Up"
        }</p>
      </form>
    </>
  )
}

export default Forml