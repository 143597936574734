import bg2 from "./bg2.jpg";
import offc from "./offc.jpg";
import cmmts1 from "./cmmts1.jpg";
import cmmts2 from "./cmmts2.jpg";

const IMGS = {
  bg2: bg2,
  offc: offc,
  cmmts1: cmmts1,
  cmmts2: cmmts2,

};

export default IMGS;
