import React from 'react'
import { useInView } from 'react-intersection-observer'

import {PiShieldWarningBold, PiChartLineUpBold} from 'react-icons/pi'
import {FcPieChart} from 'react-icons/fc'
import '../styles/services.css'

const Services = () => {
  const { ref: myRef, inView: myView} = useInView();
  const { ref: urRef, inView: urView} = useInView();
  const { ref: herRef, inView: herView} = useInView();

  console.log(myView);
  return (
    <>
    <div className="services">
      <div className="service-head">
        <h6 className='dashed-txt'>Our Awesome Services</h6>
        <h2 className='boldhead'>Our Services</h2>
      </div>
      <div className="service-list">
        <div className={"service-box anihide" + (myView ? " anishow" : " ")} style={{ "--i" : "2"}} ref={myRef} >
          <PiShieldWarningBold />
          <h3>Financial Guidance</h3>
          <p>Scale up for trading risk</p>
        </div>
        <div className={"service-box anihide" + (urView ? " anishow" : " ")} style={{ "--i" : "4"}} ref={urRef}>
          <PiChartLineUpBold />
          <h3>Asset Management</h3>
          <p>Trying to get out of the break even stage</p>
        </div>
        <div className={"service-box anihide" + (herView ? " anishow" : " ")} style={{ "--i" : "6"}} ref={herRef}>
          <FcPieChart />
          <h3>Risk Management</h3>
          <p>This is for you if you are looking to reduce loses</p>
        </div>
      </div>      
    </div>
    </>
  )
}

export default Services