import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import moment from 'moment';

import '../styles/transaction.css'

import { getAccount } from '../../database/actions/account';

const Transactions = () => {
  const {account} = useSelector((state) => state.accounts);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const dispatch = useDispatch()
  const { id } = useParams();

  // useEffect(() => {
  //   dispatch(getAccount(id));
  // }, [])
  return (
    <>
    <div className="trans">
      <div className="trans-header">
        <h2>History</h2>
      </div>
      <div className="trans-container">
      <div className="transactions">
              <table className="transact">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Transaction ID</th>
                    <th>Address</th>
                    <th>Amount</th>
                    <th>Gas Fee</th>
                    <th>Total</th>
                    <th>Details</th>
                    <th>Status</th>
                    <th>Post Balance</th>
                  </tr>
                </thead>
                  { !user.result?.transaction?.all?.length ? "Loading..." : (
                <tbody>
                  {account.transaction.all.map((option, index) => (
                    <tr key={index}>
                    <td data-label="Date">{moment(option?.date).fromNow()}</td>
                    <td className='idid' data-label="Transaction ID">{option?._id}</td>
                    <td data-label="Address">{option?.username}</td>
                    <td className='amount' data-label="Amount">${option.amount}</td>
                    <td data-label="Gas fee">{option.charge}</td>
                    <td data-label="Total">${option?.payable}</td>
                    <td data-label="Details">{option.details}</td>
                    <td data-label="Status">{option.status}</td>
                    <td data-label="Post Balance">${option?.balance}</td>
                  </tr>
                  ))}
                </tbody>
                  )
                  }
              </table>
              </div>
            </div>
      </div>
    </>
  )
}

export default Transactions