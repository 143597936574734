import React from 'react'
import { Link } from "react-router-dom";

import { FiFacebook } from "react-icons/fi";
import { BsInstagram, BsTwitter } from "react-icons/bs";
import { TbBrandTelegram } from "react-icons/tb";
import { BiSolidMessageAltCheck } from "react-icons/bi";
import { FaLocationDot } from "react-icons/fa6";

import '../styles/footer.css'
import IMGS from '../assets'
import TC from '../../assets/rawFiles/TC.pdf'
import PP from '../../assets/rawFiles/PP.pdf'

const Footer = () => {
  const imgBGStyles = {
    backgroundImage: `url(${IMGS.ftr})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  return (
    <>
      <div className="footer"  style={imgBGStyles}>
        <div className="footer-container">
          <Link to="/">
            <div className="img " style={{ "--i" : "1"}}> <h2>TradeView Guild</h2> </div>
          </Link>
          <ul>
            <li className='' style={{ "--i" : "2"}}><a href={PP} download>Privacy & Policy</a></li>
            <li className='' style={{ "--i" : "2"}}> <a href={TC} download>Terms & Condition</a></li>
          </ul>
          <div className="contact">
            <h3 className='mailicon'><BiSolidMessageAltCheck /> <a href="tel:+447760617781">+447760617781</a></h3>
            <h3 className='locicon'><FaLocationDot />The Square Mile, Beckhenham, London</h3>
          </div>
        </div>
        <div className="footer-bottom">
          <p className='' style={{ "--i" : "2"}}>© 2023 <Link to='/'>TV-G</Link>  All rights reserved</p>
          <ul>
            <li className='' style={{ "--i" : "2"}}><Link to='/'><FiFacebook /> </Link></li>
            <li className='' style={{ "--i" : "2"}}><Link to='/'><BsInstagram /></Link></li>
            <li className='' style={{ "--i" : "2"}}><Link to='/'><TbBrandTelegram /></Link></li>
            <li className='' style={{ "--i" : "2"}}><Link to='/'><BsTwitter /></Link></li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Footer