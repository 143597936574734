import React, { useState, useEffect } from 'react';

import "../styles/deposit.css";
import DepositPP from './DepositPP';
import PaymentGate from './PaymentGate';

const Deposit = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));

  const [depositPP, setDepositPP] = useState(false);
  const [depositGG, setDepositGG] = useState(false);


  const [depositValue, setDepositValue] = useState({
    currency: '',
    amount: '',
    charges: '',
    payable: '',
    text: '',
    paymentProof: '',
    wallet: '',
  });

  let balance = Number(user.result?.transaction?.balance?.mainAccount)
  balance = Number(user.result?.transaction?.balance?.mainAccount) + Number(depositValue.payable)

  const formData = {
    "username": `${depositValue.currency}`, "charge": `+ ${depositValue.charges}`,
    "payable": depositValue.payable, "amount": Number(depositValue.amount),
    "details": `Deposit`, "status": `Pending`,
    "balance": balance, "paymentProof": `${depositValue.paymentProof}`
  }


  const currencyValue = [{ value: 'USDT' }, { value: 'Bitcoin' }, { value: 'Ethereum' }, { value: 'Litecoin' }, { value: 'Solana'}]


  const Currencied = (value) => {
    setDepositValue({ ...depositValue, currency: value })
  }

  useEffect(() => {
    let percent = 0;
    let total = 0
      percent = (depositValue.amount * 0.005).toFixed(2);
      total = Number(depositValue.amount) + Number(percent);
    setDepositValue({ ...depositValue, payable: total, charges: percent });
  }, [depositValue.amount]);

  useEffect(() => {
    if (depositValue.currency === 'Bitcoin'){
      setDepositValue({ ...depositValue, wallet: "bc1qwj47pfmzgh2cwaq8kcwapfx6rgfdqf5cvydftu"})
    }
    else if (depositValue.currency === 'Ethereum'){
      setDepositValue({ ...depositValue, wallet: "0xF56B4Ef06770F91Bd9C862D4BB58506F67aFF6d1"})
    }
    else if (depositValue.currency === 'Litecoin'){
      setDepositValue({ ...depositValue, wallet: "ltc1qr6uw9c5r9fth0dp20gcghhcw8yv6tcfup2hlvh"})
    }
    else if (depositValue.currency === 'USDT'){
      setDepositValue({ ...depositValue, wallet: "TUnVuxjhCQW19ZiEGy37qZBQE3XBx9UPb7"})
    }
    else if (depositValue.currency === 'Solana'){
      setDepositValue({ ...depositValue, wallet: "ApuZRjVbQ2zFE6wnRDjKvNALoWG4TFQPbtC2P344h3gN"})
    }
  }, [depositValue.currency]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (depositValue.amount < 5){
      setDepositValue({ ...depositValue, text: "!!! Amount must be greater than $5" })
    }
    else if ( depositValue.currency === ''){
      setDepositValue({ ...depositValue, text: "!!! Please select a currency" })
    }
    else if ( depositValue.amount === ''){
      setDepositValue({ ...depositValue, text: "!!! Please Enter Amount" })
    }
    else if ((depositValue.amount > 5 || depositValue.amount !== '')   && depositValue.currency !== '') {
    setDepositPP(true);
    }
  }
  return (
    <>
    <div className="depo">
      <div className="deposit beforeWas">
        <div className="deposit-header">
        </div>
        <div className="deposit-container zl1">
          <div className="deposit-box ">
          <h2>Fund Your Account</h2>
            <p className="texts"> {depositValue.text}</p>
            <form onSubmit={handleSubmit}>
              <div className="form-cont transfer-input">
                <input type="number" name="amount" id="amount" value={depositValue.amount} onChange={(e) => setDepositValue({ ...depositValue, amount: e.target.value })} required />
                <div className="underline"></div>
                <label htmlFor="amount">Amount</label>
              </div>

              <ul className="currencied">
                <h4>Choose Payment Method from the list below</h4>
                {
                  currencyValue.map(option => (
                    <li key={option.value} onClick={() => Currencied(option.value)} className={(depositValue.currency.toLowerCase() === option.value.toLowerCase()) ? "selected" : " "}>
                      <h4>{option.value}</h4>
                    </li>
                  )
                  )}
              </ul>

              <button type="submit" className='btn-hover'> Deposit </button>
            </form>
          </div>
        </div>
        </div>
        <PaymentGate formData={formData} depositGG={depositGG} setDepositGG={setDepositGG} depositValue={depositValue} setDepositValue={setDepositValue} user={user} />
        <DepositPP depositPP={depositPP} setDepositPP={setDepositPP} depositValue={depositValue} setDepositGG={setDepositGG}/>

      </div>
    </>
  )
}

export default Deposit