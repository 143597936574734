import React, { useEffect, useState } from 'react';

import '../styles/auth.css'
import Forml from './Forml';
import { signin, signup } from '../../database/actions/auth';

const Register = () => {
  const [isSignUp, setIsSignUp] = useState(false);
  useEffect(() => {
    setIsSignUp(true);
  }, []);
  return (
    <>
      <div className="auth">
        <div className="auth-container">
          <div className="login">
            <Forml signin={signin} signup={signup} setIsSignUp={setIsSignUp} isSignUp={isSignUp} />
          </div>
        </div>
      </div>
    </>
  )
}

export default Register