import React from 'react'

import '../styles/referred.css';

const Reffered = () => {
  return (
    <>
      <div className="referred refBG beforeWas">
        <div className="ref-header zl1">
          <h2>Referred Users</h2>
        </div>
        <div className="ref-cont zl1">
          <div className="transactions">
            <table className="transact">
              <thead>
                <tr>
                  <th>SN</th>
                  <th>Bot Address</th>
                  <th>Username</th>
                  <th>Fullname</th>
                  <th>Joined At</th>

                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='amount' data-label="Date">1</td>
                  <td className='amount' data-label="Bot address">...</td>
                  <td data-label="Username">...</td>
                  <td data-label="Fullname">...</td>
                  <td data-label="Joined At">...</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default Reffered