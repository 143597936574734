import React, {useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';

import '../styles/dashboard.css';
import { CgDollar } from "react-icons/cg";
import { FaCubes } from "react-icons/fa";
import { GiWallet } from "react-icons/gi";
import { IoIosPeople } from "react-icons/io";
import { BsFillCreditCard2BackFill, BsCloudDownloadFill } from "react-icons/bs";

import BGS from '../assets'
import { getAccount } from '../../database/actions/account';


const Dashboard = () => {
  const {account} = useSelector((state) => state.accounts);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const dispatch = useDispatch()
  


  useEffect(() => {
    dispatch(getAccount(user.result._id));
  }, [dispatch, user.result._id])


  const imgBGStyles = {
    backgroundImage: `url(${BGS.bg1})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <>
      <div className="dashboard">
        <div className="dashboard-container">
          <div className="dashboad-header" style={imgBGStyles}><h2>Dashboard</h2></div>

          <div className="dashboard-body">


            <div className="dashboard-details">
              <ul className='wallet-balance'>
                <li>
                  <div className="bal-left">
                    <p>Main Account</p>
                    <h4>${account?.transaction?.balance?.mainAccount}</h4>
                  </div>
                  <div className="bal-right">
                    <p><CgDollar /></p>
                  </div>
                </li>
                <li>
                  <div className="bal-left">
                    <p>Profit Balance</p>
                    <h4>${account?.transaction?.balance?.interestBonus}</h4>
                  </div>
                  <div className="bal-right">
                    <p><GiWallet /></p>
                  </div>
                </li>
                <li>
                  <div className="bal-left">
                    <p>total investments</p>
                    <h4>${account?.transaction?.balance?.invested}</h4>
                  </div>
                  <div className="bal-right">
                    <p><FaCubes /></p>
                  </div>
                </li>
                <li>
                  <div className="bal-left">
                    <p>total deposit</p>
                    <h4>${account?.transaction?.balance?.deposited}</h4>
                  </div>
                  <div className="bal-right">
                    <p><BsFillCreditCard2BackFill /></p>
                  </div>
                </li>
                <li>
                  <div className="bal-left">
                    <p>total withdraw</p>
                    <h4>${account?.transaction?.balance?.withdrawn}</h4>
                  </div>
                  <div className="bal-right">
                    <p><BsCloudDownloadFill /></p>
                  </div>
                </li>
                <li>
                  <div className="bal-left">
                    <p>referral Bonus</p>
                    <h4>${account?.transaction?.balance?.referralBonus}</h4>
                  </div>
                  <div className="bal-right">
                    <p><IoIosPeople /></p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="transactions">
              <table className="transact">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Transaction ID</th>
                    <th>Name</th>
                    <th>Amount</th>
                    <th>Gas Fee</th>
                    <th>Total</th>
                    <th>Details</th>
                    <th>Status</th>
                    <th>Post Balance</th>
                  </tr>
                </thead>
                <tbody>
                  { !account?.transaction?.all?.length ? <tr><td>Liading...</td></tr>: (account.transaction.all.map((option, index) => (
                    <tr key={index}>
                    <td data-label="Date">{moment(option?.date).fromNow()}</td>
                    <td className='idid' data-label="Transaction ID">{option?._id}</td>
                    <td data-label="Name">{option?.username}</td>
                    <td className='amount' data-label="Amount">${option.amount}</td>
                    <td data-label="Gas fee">{option.charge}</td>
                    <td data-label="Total">${option?.payable}</td>
                    <td data-label="Details">{option.details}</td>
                    <td data-label="Status">{option.status}</td>
                    <td data-label="Post Balance">${option?.balance}</td>
                  </tr>
                  )))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard