import React, { useEffect } from 'react'
import anime from 'animejs'
import { ColorRing } from 'react-loader-spinner'

import '../styles/loaded.css'

const Loaded = () => {
  useEffect(() => {
    anime({
      targets: '#strokeanime path',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutQuad',
      duration: 3000,
      direction: 'alternate',
      loop: true,
    })
  }, [])
  return (
    <>
      <div className="loaded mh1">
        <ColorRing visible={true} width="150px" height="150px" ariaLabel="blocks-loading" colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']} />
      </div>
    </>
  )
}

export default Loaded