import React from 'react'
import { useInView } from 'react-intersection-observer'

import '../styles/cert.css'

const Certificate = () => {
  const { ref: aRef, inView: aView} = useInView();
  const { ref: bRef, inView: bView} = useInView();
  const { ref: cRef, inView: cView} = useInView();
  const { ref: dRef, inView: dView} = useInView();
  const { ref: eRef, inView: eView} = useInView();
  return (
    <>
      <div className="certificate">
        <div className="cert-footer">
          <ul>
            <li className={'anihide'  + (aView ? " anishow" : " ")} ref={aRef} style={{ "--i" : "1"}}>
              <div className="svg"></div>
              <h4>Best CFD Broker</h4>
              <p>TradeON Summit 2020</p>
            </li>
            <li className={'anihide'  + (bView ? " anishow" : " ")} ref={bRef} style={{ "--i" : "2"}}>
              <div className="svg"></div>
              <h4>Best Trading Experience</h4>
              <p>Jordan Forex EXPO 2015</p>
            </li>
            <li className={'anihide'  + (cView ? " anishow" : " ")} ref={cRef} style={{ "--i" : "3"}}>
              <div className="svg"></div>
              <h4>Best Execution Broker</h4>
              <p>TradeON Summit 2020</p>
            </li>
            <li className={'anihide'  + (dView ? " anishow" : " ")} ref={dRef} style={{ "--i" : "4"}}>
              <div className="svg"></div>
              <h4>Best Trading Platform</h4>
              <p>London Summit 2016</p>
            </li>
            <li className={'anihide'  + (eView ? " anishow" : " ")} ref={eRef} style={{ "--i" : "5"}}>
              <div className="svg"></div>
              <h4>Best Broker Asia</h4>
              <p>iFX EXPO 2018</p>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Certificate