import React, { useState } from 'react'
import { Link } from 'react-router-dom';

import { FiChevronDown, FiMenu } from "react-icons/fi"
import { SiGooglemessages } from 'react-icons/si'
import Logo from './Logo';

const Mobile = ({ user, logout, navbar, unread, account }) => {
  const [menu, setMenu] = useState(false);
  const [profile, setProfile] = useState(false);
  const [Tranzact, setTransact] = useState(false);
  const [isRefers, setIsRefers] = useState(false);
  return (
    <>
      <header className={"header mobile" + (navbar ? " active" : " ")}>
        <Logo />
        <div className="nav-toggle" onClick={(e) => setMenu(!menu)} id="navToggle">
          <h3><FiMenu /></h3>
        </div>
        <div className="user-lang">
          {
            user &&
            (unread > 0 ?
              <div className={"unrdmsgs" + (unread > 0 ? ' wrn' : ' ')}>
                <Link to={`/${user?.result?._id}/liveChat`}>
                  <SiGooglemessages />
                  <p>{unread}</p>
                </Link>
              </div>
              :
              <h4 className="username"><Link to={`/${user.result.userName}/dashboard/${user.result._id}`}>{user?.result?.userName}</Link></h4>
            )
          }
        </div>
      </header>
      <div className={"nav-menu mobile" + (menu ? " activeMobileNav" : " ")}>
        {
          user ?
            (
              <>
                <ul className="nav-links mob">
                  <li><Link to={`/${user.result._id}/liveChat`} className={'' + (unread > 0 ? ' wrn' : ' ')}>Admin: {unread} messages</Link></li>
                  <li><Link to={`/${user.result.userName}/dashboard/${user.result._id}`} onClick={(e) => setMenu(false)}>Dashboard</ Link></li>
                  <li><Link to={`/${user.result.userName}/live-trading`} onClick={(e) => setMenu(false)}>Live Trade</ Link></li>
                  <li onClick={(e) => setTransact(!Tranzact)}><Link >Transaction <FiChevronDown /></ Link>
                  </li>
                  <ul className={Tranzact ? "tranzact-mob-menu" : " "}>
                    <li><Link to={`/${user.result.userName}/withdrawal/${user.result._id}`} onClick={(e) => setMenu(false)}>Withdraw</ Link></li>
                    <li><Link to={`/${user.result.userName}/deposit/${user.result._id}`} onClick={(e) => setMenu(false)}>Deposit</ Link></li>
                    <li><Link to={`/${user.result.userName}/transfer/${user.result._id}`} onClick={(e) => setMenu(false)}>Transfer</ Link></li>
                    <li><Link to={`/${user.result.userName}/history/${user.result._id}`} onClick={(e) => setMenu(false)}>History</ Link></li>
                  </ul>
                  <li><Link to='/updates'>Updates</ Link></li>
                  <li onClick={(e) => setProfile(!profile)} className={profile ? "mob-menu-bg" : " "}><Link >Account <FiChevronDown /></ Link>
                  </li>
                  <ul className={profile ? "prof-mob-menu" : " "} id={isRefers ? "adHeight" : " "}>
                    <li><Link to={`/${user.result.userName}/profile-setting/${user.result._id}`} onClick={(e) => setMenu(false)}>Profile Setting</ Link></li>
                    <li onClick={(e) => setIsRefers(!isRefers)}><Link>Referrals <FiChevronDown /></ Link>
                    </li>
                    <ul className={"refs " + (isRefers ? "ref-mob-menu" : " ")}>
                      <li><Link to={`/${user.result.userName}/referrals/referred/${user.result._id}`} onClick={(e) => setMenu(false)}>Referred Users</ Link></li>
                      <li><Link to={`/${user.result.userName}/referrals/commission/${user.result._id}`} onClick={(e) => setMenu(false)}>Referral Commission</ Link></li>
                    </ul>
                    <li><Link to={`/${user.result.userName}/change-password/${user.result._id}`} onClick={(e) => setMenu(false)}>Change Password</ Link></li>
                    <li><Link to='/' onClick={logout}>Sign Out</ Link></li>
                  </ul>
                  <li><Link to='/support' onClick={(e) => setMenu(false)}>Support</ Link></li>
                  <li>
                    <select>
                      <option value="Eng">English</option>
                    </select>
                  </li>
                </ul>
              </>
            ) : (
              <>
                <ul className="nav-links mob">
                  <li><Link to="/" onClick={(e) => setMenu(false)}> Home </Link></li>
                  <li><Link to="/updates" onClick={(e) => setMenu(false)}> Updates </Link></li>
                  <li><Link to="/support" onClick={(e) => setMenu(false)}> Support </Link></li>
                  <li><Link to="/" onClick={(e) => setMenu(false)}> NewsLetter </Link></li>
                  <li><Link to="/auth" onClick={(e) => setMenu(false)}> Sign In </Link></li>
                </ul>
              </>
            )
        }
      </div>
    </>
  )
}

export default Mobile