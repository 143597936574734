import React from 'react'
import { Link } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'

import { ReactComponent as Trtools} from '../../assets/icon/trtools.svg'
import { ReactComponent as Fast} from '../../assets/icon/fast.svg'
import { ReactComponent as FinSec} from '../../assets/icon/finSec.svg'
import { ReactComponent as Guide} from '../../assets/icon/guide.svg'
import { ReactComponent as MinVal} from '../../assets/icon/minVal.svg'
import { ReactComponent as StrReg} from '../../assets/icon/strReg.svg'

import '../styles/partner.css'

const Partner = () => {
  const { ref: aRef, inView: aView} = useInView();
  const { ref: bRef, inView: bView} = useInView();
  const { ref: cRef, inView: cView} = useInView();
  const { ref: dRef, inView: dView} = useInView();
  const { ref: eRef, inView: eView} = useInView();
  const { ref: fRef, inView: fView} = useInView();
  const { ref: gRef, inView: gView} = useInView();
  const { ref: hRef, inView: hView} = useInView();
  const { ref: iRef, inView: iView} = useInView();

  return (
    <>
    <div className="partner">
        <div className="partner-left">
            <h2 className={'anihide'  + (aView ? " anishow" : " ")} ref={aRef} style={{ "--i" : "1"}}>A partner invested in your success</h2>
            <p className={'anihide'  + (bView ? " anishow" : " ")} ref={bRef} style={{ "--i" : "2"}}>Trade with confidence and benefit from the reliability of a trusted broker with a proven record of stability, security and strength.</p>
            <Link to='/signup' className={"btn-hover anihide"  + (cView ? " anishow" : " ")} ref={cRef} style={{ "--i" : "2"}}>Open an Account</Link>
        </div>
        <div className="partner-right">
            <ul>
                <li><MinVal className={'anihide'  + (dView ? " anishow" : " ")} ref={dRef} style={{ "--i" : "1"}}/><p className={''  + (dView ? " anishow" : " ")} ref={dRef} style={{ "--i" : "1"}}>Maximum value</p></li>
                <li><Fast className={'anihide'  + (eView ? " anishow" : " ")} ref={eRef} style={{ "--i" : "2"}}/><p className={''  + (eView ? " anishow" : " ")} ref={eRef} style={{ "--i" : "2"}}>Fast executions</p></li>
                <li><Trtools className={'anihide'  + (fView ? " anishow" : " ")} ref={fRef} style={{ "--i" : "3"}}/> <p className={''  + (fView ? " anishow" : " ")} ref={fRef} style={{ "--i" : "3"}}>Trading tools</p></li>
                <li><Guide className={'anihide'  + (gView ? " anishow" : " ")} ref={gRef} style={{ "--i" : "1"}}/><p className={''  + (gView ? " anishow" : " ")} ref={gRef} style={{ "--i" : "1"}}>Guide & support</p></li>
                <li><FinSec className={'anihide'  + (hView ? " anishow" : " ")} ref={hRef} style={{ "--i" : "2"}}/><p className={''  + (hView ? " anishow" : " ")} ref={hRef} style={{ "--i" : "2"}}>Financial secure</p></li>
                <li><StrReg className={'anihide'  + (iView ? " anishow" : " ")} ref={iRef} style={{ "--i" : "3"}}/><p className={''  + (iView ? " anishow" : " ")} ref={iRef} style={{ "--i" : "3"}}>Strong regulatory</p></li>
            </ul>
        </div>
    </div>
    </>
  )
}

export default Partner